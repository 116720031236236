import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

export default {
  palette: {
    mainBlue: "#199FDF",
    darkBlue: "#000CBA",
    mainRed: "#F30704",
    mainYellow: "#FBCB2E",
    mainGreen: "#B0C92D",

    navCol: "#1D8DBE",
    spanCol: "#F30704",
    lightCol: "#199FDF",
    darkCol: "#2a2a2a",
    ctaCol: "#A52A2A",
    lightText: "#fff",
    darkText: "#000",
    lightGrey: "#E1E1E1",
    blueHightlight: "#146988",
  },
  breakpoints: {
    smallest: "500px",
    small: "768px",
    medium: "900px",
    large: "1100px",
    xl: "1600px",
  },
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#71cc98",
    },
    secondary: {
      main: "#ebebeb",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    h1: {},
    h2: {
      fontFamily: "Libre Baskerville",
      fontWeight: "700",
      marginBottom: 30,
    },
    h3: {
      fontFamily: "Libre Baskerville",
      marginBottom: 30,
      lineHeight: "70px",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "25px",
      paddingBottom: 10,
      textAlign: "left",
    },
    h5: {
      fontFamily: "Libre Baskerville",
      fontWeight: "bold",
      fontSize: "20px",
      marginTop: "10px",
      marginBottom: "10px",
      color: "#71cc98",
      textAlign: "left",
      paddingTop: "10px",
    },
    h6: {
      textAlign: "center",
      color: "#71cc98",
      letterSpacing: "7px",
      textTransform: "uppercase",
      marginTop: 50,
      marginBottom: 30,
      fontSize: 30,
    },
    body2: {
      fontSize: "16px",
      lineHeight: "25px",
    },
    button: {},
  },
})

if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  window.theme = theme
}
