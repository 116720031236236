// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-deckensektionaltore-js": () => import("./../src/pages/deckensektionaltore.js" /* webpackChunkName: "component---src-pages-deckensektionaltore-js" */),
  "component---src-pages-drehtorantriebe-js": () => import("./../src/pages/drehtorantriebe.js" /* webpackChunkName: "component---src-pages-drehtorantriebe-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-garagentorantriebe-js": () => import("./../src/pages/garagentorantriebe.js" /* webpackChunkName: "component---src-pages-garagentorantriebe-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrietore-js": () => import("./../src/pages/industrietore.js" /* webpackChunkName: "component---src-pages-industrietore-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-schiebetorantriebe-js": () => import("./../src/pages/schiebetorantriebe.js" /* webpackChunkName: "component---src-pages-schiebetorantriebe-js" */),
  "component---src-pages-schwingtore-js": () => import("./../src/pages/schwingtore.js" /* webpackChunkName: "component---src-pages-schwingtore-js" */),
  "component---src-pages-seitensektionaltore-js": () => import("./../src/pages/seitensektionaltore.js" /* webpackChunkName: "component---src-pages-seitensektionaltore-js" */),
  "component---src-pages-sektionaltore-js": () => import("./../src/pages/sektionaltore.js" /* webpackChunkName: "component---src-pages-sektionaltore-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

